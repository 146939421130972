<div class="menu-bars notranslate">
  <div class="chip" (click)="openColDialog()">
    <span >Cols</span>
  </div>
  <div class="chip" >
    <span style="display: contents;" >Freeze: <u>{{pageFreezeColumn}}</u></span>
    <img src="./../../../../../../assets/Icons/freeze-Icon.svg" class="img-design" alt="freeze-icon" (click)="toggleFreeze($event)">
  </div>

<div class="chip">
  <label for="expand-level-input" style="margin-bottom: 0px;">Expand:</label>
  <input type="text" maxlength="10"
  oninput="this.value = this.value.slice(0, 10);" class="expand-input-level" id="expand-level-input" type="number" [(ngModel)]="ExpandLevels.n" (input)="onExpandChange(ExpandLevels.n.toString());adjustInputWidth();" autocomplete="off" [ngStyle]="{width:inputWidth}">
  <span class="expand-toggle" (click)="onExpandToggle($event); ">
    <img src="./../../../../../../assets/Icons/freeze-Icon.svg" alt="expand-icon" class="img-design">
  </span>
</div>
  <div class="chip" >
    <span>Sort</span>
    <input type="checkbox" id="sortCheckbox" name="sortCheckbox" class="checkbox" (change)="toggleSort()">
  </div>
  <div class="chip" >
    <span>Filter</span>
    <input type="checkbox" id="filterCheckbox" name="filterCheckbox" class="checkbox" (change)="toggleFilter()">
  </div>
  <div class="chip" (click)="clearFilters()">
    <span style="color:red" >Clear</span>
  </div>
</div>
  <div #tabulatorDiv2 class="tabulator2"></div>
  