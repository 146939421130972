import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';

import {
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { PGColDialogComponent } from '../pgcol-dialog/pgcol-dialog.component';
import { Sheet } from '../../store/sheet/sheet.actions';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../store/sheet/sheet.store';
import { expand, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MenuBarService } from '../../services/menu-bar-service/manu-bar.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PickddiPGColDialogComponent } from './pickddi-pgcol-dialog/pickddi-pgcol-dialog.component';
import { COL_STATUSES, PAGE_IDS } from '../../constant';
import { CdkPortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-pick-ddi-dialogue',
  templateUrl: './pick-ddi-dialogue.component.html',
  styleUrls: ['./pick-ddi-dialogue.component.css']
})
export class PickDdiDialogueComponent implements OnInit, OnDestroy,AfterViewInit {
  private tabulatorTable!: Tabulator;
  @ViewChild('tabulatorDiv2', { static: true }) tabulatorDiv2!: ElementRef | undefined;
  private unsubscribe$ = new Subject<void>();
  allData: any = ([] = []);
  allDataWithoutNested: any = ([] = []);
  allColumns: any = ([] = []);
  freezLevel: any = -1;
  pageFreezeColumn: any = 0;
  frozen:any[]=[];
  ExpandLevels: any = { n: 99, x: null, y: null, z: null };
  public sortStatus = false;
  public sorted=false;
  private isFilter: boolean = false;
  firstIncrement: boolean = true;
  rowLevel:any=0;
  private sub: Subscription | null = null;
  @Select(SheetState.PickDdiData) data$: Observable<any> | undefined;
  @Select(SheetState.PickDdiColumns) columns$: Observable<any> | undefined;
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  @Select(SheetState.getExpandWidth) expandWidth$: Observable<any> | undefined;
  expandLevels: boolean[] = [];
  selectedColumns: any[] = [];

  inputWidth = '16px'; // Initial width
constructor(
  private store: Store,
  private menuBarService: MenuBarService,
  public dialog: MatDialog,
  @Inject(MAT_DIALOG_DATA) public data: any
) {
}
 
  ngOnInit() {
    this.getData()
    this.renderTabulator();
    this.freezeLevel();
    this.expandLevel();
    this.store.dispatch(new Sheet.SetFrozen(this.data?.data?.frozen));
    this.store.dispatch(new Sheet.SetWidthExpand(this.data?.data?.expandWidth));
    if(this.data?.data?.frozen!=undefined){
      this.ExpandLevels.n=this.data?.data?.frozen;
    }
    if(this.data?.data?.expandWidth!=undefined){
      this.inputWidth=this.data?.data?.expandWidth;
    }
   
  }
  ngAfterViewInit(){
    this.freezeLevel();
    this.expandLevel();
  }
  getData() {
    // Reset state
    this.store.reset(new Sheet.PickDdiData(PAGE_IDS.ALL_REGIONS));
    
    // Dispatch new state
    this.store.dispatch(new Sheet.PickDdiData(PAGE_IDS.ALL_REGIONS));
    this.data$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if(data!=undefined){
        this.allDataWithoutNested = [...data];
        this.allData = this.buildNestedDataIterative(data);
        console.log('data', this.allData);
      }
    });
  }

hasChildrenInAnyRow = false;

buildNestedDataIterative(inputData: any) {
  this.hasChildrenInAnyRow = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
        const newItem = {
            ...item,
        };

        if (item?.ParentRow?.Row === null || item?.ParentRow === null) {
            structuredData.push(newItem);
        } else {
          if(item?.ParentRow?.Row !== null ) {
            this.hasChildrenInAnyRow =true;
          }
            const parentRow = map[item?.ParentRow?.Row];
            if (parentRow) {
                if (!parentRow._children) {
                    parentRow._children = []; // Initialize only if it doesn't exist
                }
                parentRow._children.push(newItem);
            }
        }

        map[item?.row] = newItem;
    });

    return structuredData;
}

  dataSelection() {
    let selectedColumns;
    selectedColumns = this.allData;
    return selectedColumns;
  }

  underlineFormatter = (cell: { getValue: () => any }) => {
    const value = cell.getValue();
    if(value) {
      return `<span style="text-decoration: underline;">${value}</span>`;
    }else {
      return value;
    }
  };

  boldSectionHead = (cell: { getValue: () => any, getData: () => any }) => {
    const value = cell.getValue();
    const data = cell.getData();
    if (data.RowLevel == 0) {
      return `<span style="font-weight: bold;">${value}</span>`;
    }
    return value;
  };

// Check if the column contains any semicolon
checkColumnForSemicolons(column: any): boolean {
  return this.checkColStatus(column.status);
}
adjustInputWidth() {
  const characterWidth = 7; // Adjust this value as needed
  const buffer = 5; // Small buffer for the right side
  this.inputWidth = (this.ExpandLevels.n.length * characterWidth) + buffer + 'px';
  // this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
  if(this.ExpandLevels.n==999999999){}else{
    if(this.ExpandLevels.n.toString().length>1){
      this.inputWidth='16px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>2){
      this.inputWidth='25px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>3){
      this.inputWidth='30px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>4){
      this.inputWidth='37px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>5){
      this.inputWidth='42px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>6){
      this.inputWidth='50px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>7){
      this.inputWidth='56px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>8){
      this.inputWidth='70px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
    if(this.ExpandLevels.n.toString().length>9){
      this.inputWidth='68px';
      this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
    }
  }
}



generateColumnCode(columnSelection: any = []) {
  const columns = columnSelection.map((column: any, index: number) => {
    const hasSemicolon = this.checkColumnForSemicolons(column); // Check if the column has semicolons
    const formatter = (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // If the column has a semicolon, format all items in that column with chips
      if (hasSemicolon) {
        return this.chipFormater()(cell, formatterParams, onRendered);
      } else {
        return value; // Return the original value if no semicolon
      }
    };

    if (index < +(this.freezLevel) && this.pageFreezeColumn!=0) {
      if (column.visible === true) {
        column.frozen = true;
      }
    }
    if (column.status.includes('Nested')) {
      return {...column, formatter: this.boldSectionHead }
    }

    // Use underline formatter for "Page URL" column
    if (column.field === 'page_url' && column.field !== 'undefined') {
      return { ...column, formatter: this.underlineFormatter };
    } else {
      return { ...column, formatter }; // Apply the dynamic formatter to other columns
    }
  });
  
  return columns;
}


headerClickFunc = (e: any, column: any) => {
  var tabulator = column.getTable();
  this.sortStatus ? '' : tabulator.clearSort();
  
};


toggleSort() {
  this.sortStatus=!this.sortStatus;
  if(this.sortStatus==false){
    this.tabulatorTable.clearSort();
  }
 
}
openColDialog() {
  const dialogRef = this.dialog.open(PickddiPGColDialogComponent, {
    width: '750px',
    data: {},
  });

  dialogRef.afterClosed().subscribe(() => {
    console.log('The dialog was closed');
  });
}


toggleFreeze(event: MouseEvent): void {

  const target = event.currentTarget as HTMLElement;
  const rect = target.getBoundingClientRect();
  const y = event.clientY - rect.top;
  const centralAreaHeight = rect.height * 0.2;
  if (y < rect.height / 2 - centralAreaHeight / 2) {
    this.pageFreezeColumn += 1;
    let obj1 = { pageFreezeColumn: this.pageFreezeColumn };
    this.menuBarService.pageFormateFreeze.next(obj1);
    
  } else if (
    y > rect.height / 2 + centralAreaHeight / 2 &&
    this.pageFreezeColumn > 0
  ) {
    this.pageFreezeColumn = Math.max(0, this.pageFreezeColumn - 1);
    let obj2 = { pageFreezeColumn: this.pageFreezeColumn };
    this.menuBarService.pageFormateFreeze.next(obj2);
    this.tabulatorTable.getColumns()?.map((column)=>{
      if(column.getDefinition().frozen==true){
        column.updateDefinition({frozen:false,title:column.getDefinition().title});
        this.renderTabulator();
      }
    })
  }
}

  chipFormater() {
    return (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // Create a container for chips
      const chipContainer = document.createElement('div');
      chipContainer.classList.add('chip-container');

      // Split by semicolon or treat as single item
      const items =
        value && typeof value === 'string'
          ? value.includes(';')
            ? value.split(';').map((item) => item.trim())
            : [value.trim()]
          : [];

      // Create chips for each item
      items.forEach((item) => {
        const chipDiv = document.createElement('div');
        chipDiv.className = 'Button';
        chipDiv.title = item; // Set the chip tooltip

        const chipContentDiv = document.createElement('div');
        chipContentDiv.className = 'chip-content';
        chipContentDiv.textContent = item;

        chipDiv.appendChild(chipContentDiv);
        chipContainer.appendChild(chipDiv);
      });

      // Remove the cell tooltip by removing the title attribute
      cell.getElement().removeAttribute('title');

      return chipContainer.outerHTML;
    };
  }

  expandLevel(): boolean[] {
    if (!this.sub) {
      this.sub = this.menuBarService.pageFormateReg.subscribe((res) => {
        if (res.pageExpand) {
                  this.expandLevels = Array(res.pageExpand.n).fill(true);
                  this.renderTabulator();
        }
      });
    }
    return this.expandLevels;
  }

  freezeLevel() {
    // this.freezLevel = 0;
    this.menuBarService.pageFormateFreeze.subscribe((res) => {
      if (res.pageFreezeColumn) {
        this.freezLevel = res.pageFreezeColumn;
        this.renderTabulator();
      }else if(res.pageFreezeColumn==this.allColumns.length){
        console.log(this.tabulatorTable.getColumns());
      }
    });
  }

 renderTabulator(): void {
  
  let columnsWithHeaderContext: any = [];
  if (this.tabulatorTable) {
      this.tabulatorTable.destroy();
  }
  
  this.columns$?.subscribe((columns) => {
    if(columns!=undefined){
      this.selectedColumns = columns;
      this.allColumns = columns.map((col: any) => ({ ...col }));
      columnsWithHeaderContext = this.generateColumnCode(columnsWithHeaderContext)?.map((column: any) => {
          return {
              ...column,
              headerClick: (e: any, column: any) => {
                  this.headerClickFunc(e, column);
              }
          };
      });
      columnsWithHeaderContext = this.selectedColumns?.map((column: any) => {
          const isVisible = column?.status?.includes(COL_STATUSES.DDS_COL);
          return {
              ...column,
              headerFilterLiveFilter: false,
              visible: isVisible,
              headerFilter: this.customFilterEditor,
              headerFilterFunc: this.customFilterFunction,
              headerClick: (e: any, column: any) => {
                  this.headerClickFunc(e, column);
              },
          };
      });
      const nestedColumn = columnsWithHeaderContext.find((column: { status?: string | string[]; }) => column.status?.includes('Nested'))?.field;

      this.tabulatorTable = new Tabulator(this.tabulatorDiv2?.nativeElement, {
          data: this.dataSelection(),
          columns: this.generateColumnCode(columnsWithHeaderContext),
          dataTree: true,
          dataTreeFilter: true,
          // dataTreeSelectPropagate:true,
          dataTreeStartExpanded: this.expandLevel(),
          dataTreeElementColumn: nestedColumn,
          addRowPos: 'bottom',
          validationMode: "highlight",
          columnDefaults: {
              resizable: true, // Ensure columns are resizable,
          },
          height: '100vh',
          progressiveLoad: 'scroll',
          layout: 'fitDataFill',  
          layoutColumnsOnNewData: true,
          movableColumns: true,
          spreadsheet: true,
          spreadsheetRows: 250,
          movableRows: false,
          
          rowFormatter: row => {
            this.formatRow(row, nestedColumn);
              row.getCells().forEach(cell => {
                  const value = cell.getValue();
                  // If the cell contains chips, remove the tooltip
                  if (value && typeof value === 'string' && value.includes(';')) {
                      cell.getElement().removeAttribute('title');
                  } else {
                      // Ensure tooltip is available for all other cells
                      cell.getElement().setAttribute('title', value);
                  }
              });

},
      });
    }
  });
  
}

formatRow(row: any, nestedColumn: string): void {
  const depth = row.getData().RowLevel; // Get the RowLevel
  const basePadding = 10; // Padding for each depth level
  const buttonOffset = -10; // Offset for the button from the left

  // Check if the row has children (_children property)
  const hasChildren = row.getTreeChildren().length > 0;

  // Only apply yellow lines if there are children anywhere in the data
  if (!this.hasChildrenInAnyRow) {
      // No lines, apply padding of 2px
      row.getCells().forEach((cell: { getElement: () => any; getField: () => string; }) => {
          const cellElement = cell.getElement();
          cellElement.style.padding = '2px'; // Apply 2px padding when no yellow lines
      });
  }

  row.getCells().forEach((cell: { getElement: () => any; getField: () => string; }) => {
      const field = cell.getField();
      if (field === nestedColumn) {
          const cellElement = cell.getElement();
          let paddingLeft = depth * basePadding + buttonOffset;

          // Clear existing lines
          Array.from(cellElement.querySelectorAll('.line')).forEach(line => {
              (line as HTMLElement).remove();
          });

          // Add yellow lines if row is nested (depth > 0)
          if (depth > 0) {
              for (let i = 0; i < depth; i++) {
                  const lineDiv = document.createElement('div');
                  lineDiv.classList.add('line');
                  lineDiv.style.left = `${i * basePadding}px`; // Adjust left position based on basePadding
                  lineDiv.style.backgroundColor = 'yellow'; // Apply yellow color for the lines
                  lineDiv.style.position = 'absolute'; // Make sure the line is absolutely positioned
                  lineDiv.style.height = '100%'; // Full height of the cell
                  lineDiv.style.width = '2px'; // Line width
                  cellElement.appendChild(lineDiv);
              }
          }

          if (!hasChildren) {
              paddingLeft += basePadding + 1;
          }

          // Apply padding for the cell to accommodate for the yellow lines
          cellElement.style.paddingLeft = `${paddingLeft}px`;

      } else {
          // Apply 2px padding for non-nested columns
          const cellElement = cell.getElement();
          cellElement.style.padding = '2px'; // Non-nested column, no lines
      }
  });
}



onExpandChange(ExpandLevelss: string) {
  // const inputElement = event.target as HTMLInputElement;
  // const textLength = inputElement.value.length;

  // // Adjust width based on text length
  // this.inputWidth = `${Math.max(16,(textLength*8))}px`; // minimum width of 100px


  this.store.dispatch(new Sheet.SetFrozen(ExpandLevelss));
  ExpandLevelss = ExpandLevelss.replace(/-/g, '').replace(/[^0-9]/g, '');
  if(ExpandLevelss.toString().length<=7){
    const level = this.parseExpandLevel(ExpandLevelss);
    this.menuBarService.Expand_Visibilt_Reg.next(level);
    let obj1 = { pageExpand: level };
    this.menuBarService.pageFormateReg.next(obj1);
  }else{
    return;
  }
}

  onExpandToggle(event: MouseEvent): void {
 
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    let nValue = this.ExpandLevels.n.toString();
    if (!nValue || isNaN(parseFloat(nValue))) {
      nValue = '0';
      this.ExpandLevels.n = 0;
      return;
    } else if (nValue.startsWith('.')) {
      nValue = '0' + nValue;
    }
    const [integerPart, decimalPart] = nValue.split('.');
    let intValue = parseInt(integerPart, 10);
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      if (this.firstIncrement) {
        this.firstIncrement = false;
      }
      intValue += 1;
      if(intValue==999999999){}else{
      if(intValue.toString().length>1){
        this.inputWidth='16px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>2){
        this.inputWidth='25px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>3){
        this.inputWidth='30px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>4){
        this.inputWidth='37px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>5){
        this.inputWidth='42px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>6){
        this.inputWidth='50px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>7){
        this.inputWidth='56px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>8){
        this.inputWidth='70px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
      if(intValue.toString().length>9){
        this.inputWidth='68px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
    }
    } else if (y > rect.height / 2 + centralAreaHeight / 2 && intValue > 0) {
      intValue = Math.max(0, intValue - 1);

      if(intValue.toString().length<2){
        this.inputWidth='10px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<3){
        this.inputWidth='17px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<4){
        this.inputWidth='24px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<5){
        this.inputWidth='30px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<6){
        this.inputWidth='37px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<7){
        this.inputWidth='43px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<8){
        this.inputWidth='49px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<9){
        this.inputWidth='57px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }else
      if(intValue.toString().length<10){
        this.inputWidth='62px';
        this.store.dispatch(new Sheet.SetWidthExpand(this.inputWidth));
      }
    }
    this.ExpandLevels.n = decimalPart
      ? `${intValue}.${decimalPart}`
      : `${intValue}`;
    this.onExpandChange(this.ExpandLevels.n); // Send updated value
  }
  parseExpandLevel(level: string): {
    n: number;
    x: number | null;
    y: number | null;
    z: number | null;
  } {
    let nValue = this.ExpandLevels.n
      ? parseInt(this.ExpandLevels.n.toString().split('.')[0])
      : 0;

    const match = level.match(
      /(\d+)(?:\.(1))?(?:(2))?(?:(3))?(?:\.(12|13|23|123))?/
    );
    if (!match) return { n: nValue, x: null, y: null, z: null }; // Default return if no match

    nValue = parseInt(match[1]); // Captures 'n'
    let xValue = match[2] ? 1 : null; // x is only 1 if matched
    let yValue = match[3] ? 2 : null; // y is only 2 if matched
    let zValue = match[4] ? 3 : null; // z is only 3 if matched

    // Handle combined cases for y and z
    if (match[5]) {
      if (match[5].includes('1')) xValue = 1; // If part of "12", "13", or "123"
      if (match[5].includes('2')) yValue = 2; // If part of "12", "23", or "123"
      if (match[5].includes('3')) zValue = 3; // If part of "13", "23", or "123"
    }

    return {
      n: nValue,
      x: xValue,
      y: yValue,
      z: zValue,
    };
  }

  toggleFilter() {
    this.isFilter = !this.isFilter;
    if (this.isFilter) {
      this.updateTableWithFilters(true);
    } else if (!this.isFilter) {
      this.updateTableWithFilters(false);
    }
  }

  clearFilters() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete()
  }

  updateTableWithFilters(enable: any) {
    var headers = document.querySelectorAll('.tabulator2.tabulator .tabulator-header');

    if (enable) {
      headers.forEach(function (header: any) {
        header.style.height = 48 + 'px';
      });
    } else {
      headers.forEach(function (header: any) {
        header.style.height = 16 + 'px';
      });
    }

    let columns = this.tabulatorTable
      ?.getColumnDefinitions()
      .map((colDef: any) => {
        colDef.headerFilter = enable ? this.customFilterEditor : false;
        return colDef;
      });

    this.tabulatorTable?.setColumns(columns);
  }

  /**
   * Custom filter editor for Tabulator's column headers.
   * This function creates an input field inside the header for custom filtering, 
   * with real-time validation, error handling, and event-based triggers for filter application.
   *
   * @param {Object} cell - The Tabulator cell object where the editor is being applied.
   * @param {Function} onRendered - Callback that is called once the editor is rendered.
   * @param {Function} success - Callback to pass the input data back to Tabulator once the filter is applied.
   * @param {Function} cancel - Callback to cancel the editor input (triggered on 'Escape').
   * @param {Object} editorParams - Additional editor parameters passed by Tabulator's configuration.
   * @returns {HTMLElement} - A container element with the input field and an optional error message display.
   */
  customFilterEditor = (
    cell: any,
    onRendered: any,
    success: any,
    cancel: any,
    editorParams: any
  ): HTMLElement => {
    // Create a container to hold the input and error message
    const container = document.createElement('span') as HTMLElement;

    // Create and style the input element for user input
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.padding = '4px';
    input.style.width = '100%';
    input.style.boxSizing = 'border-box';
    input.value = cell.getValue();

    // Create and style the error message container (initially hidden)
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'red';
    errorMessage.style.fontSize = '12px';
    errorMessage.style.marginTop = '4px';
    errorMessage.style.display = 'none';

    // Validate the input value and adjust the UI accordingly
    const validateInputValue = () => {
      const isValid = this.validateInput(input.value);
      const tableHeader = document.querySelector('.tabulator2.tabulator .tabulator-header[role="rowgroup"]') as HTMLDivElement;
      const tabulator = document.querySelector('.tabulator2.tabulator[role="grid"]') as HTMLDivElement;
      const tabulatorTable = document.querySelector('.tabulator2.tabulator[tabulator-layout=fitDataFill] .tabulator-tableholder .tabulator-table') as HTMLDivElement;
      const tabulatorPlaceholder = document.querySelector('.tabulator2.tabulator .tabulator-tableholder') as HTMLDivElement;

      if (!isValid) {
        // If input is invalid, style the input in red and display the error message
        input.style.color = 'red';
        errorMessage.textContent = 'Invalid syntax';
        errorMessage.style.display = 'block';
        errorMessage.style.marginTop = '0px';
        errorMessage.style.fontSize = '12px';
        errorMessage.style.fontWeight = '200';

        // Adjust the table header and container overflow for better visibility
        tableHeader.style.height = '60px';
        tabulator.classList.add('tabulator-100vh');
        tabulatorPlaceholder.style.overflow = 'inherit'; // Remove scrollbars
      } else {

        // If input is valid, revert the styles back to normal
        input.style.color = 'black';
        errorMessage.style.display = 'none';
        
        // Revert the table header and container styles to original settings
        tableHeader.style.height = '48px';
        tabulator.classList.remove('tabulator-100vh');
        tabulatorTable.style.width = '100%';
        tabulatorPlaceholder.style.overflow = 'auto'; // Enable scrollbars
      }
    };

    // Function to build input values and pass them back
    const buildValues = () => {
      success({
        inputColumnStatus: cell.getColumn().getDefinition().status,
        inputColumn: cell.getColumn().getField(),
        inputValue: input.value,
      });
    };

    // Debounce buildValues and validateInput functions
    const debounceBuildValues = this.debounceInput(buildValues, 300);
    const debounceValidateInput = this.debounceInput(validateInputValue, 300);

    // Input event listener (debounced)
    input.addEventListener('input', () => {
      debounceBuildValues();
      debounceValidateInput();
    });

    // Keypress listener for Enter and Escape
    input.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') buildValues();
      if (e.key === 'Escape') cancel();
    });

    // Finalize filter on blur
    input.addEventListener('blur', buildValues);

    // Append the input and error message to the container
    container.appendChild(input);
    container.appendChild(errorMessage);

    // Return the container element that will be used as the filter editor
    return container;
  };

  /**
   * Debounces a function, ensuring that the provided function `func` is invoked only after 
   * the specified `wait` time has passed since the last time the debounced function was called.
   *
   * @param {Function} func - The function to be debounced.
   * @param {number} wait - The number of milliseconds to wait before calling the function after the last invocation.
   * @returns {Function} - A new debounced function that delays the execution of `func` until after `wait` milliseconds 
   *                       have passed since the last invocation.
   */
  debounceInput(func: any, wait: any): Function {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  /**
   * Validates the input filter string based on the allowed filter types and ensures proper syntax.
   *
   * @param {string} inputValue - The filter input string to validate.
   * @returns {boolean} - Returns `true` if the input passes validation, otherwise `false`.
   */
  validateInput(inputValue: string): boolean {
    const filterTypes: any = ['Like', 'Starting', 'Ending', 'Match', 'Regex', 'And', 'Or', 'Not'];
    const validationPassed: boolean = true;
    const validationFailed: boolean = false;

    // Split the input string into filter types and filter strings
    let filterParts :string[] = inputValue.match(/\[.*?\]|[^ ]+/g) ?? [];

    // Create filter objects from the input parts
    const filters = this.createFilters(filterParts);
    
    // Check if all filters have valid types and non-empty filter strings
    const validator = filters.every((filter: any) => {
      return filter.types.every((type: any) => {
        const isValidType = filterTypes.includes(type) && filter.strings.length > 0;
        return isValidType;

        // TODO: Regex validation is not included as per requirements. Uncommnet this if needed.
        // if (type == 'Regex') {
        //   filter.strings.every((string:string) => )
        // }
      });
    });

    // If validator fails or parts couldn't be split properly, return false
    if (!validator || (inputValue && !filterParts)) {
      return validationFailed;
    }

    // Specific edge cases for syntax validation
    if (filterParts && filterParts.length > 0 && filterParts[0] === '[]') {
      return validationFailed;
    }
      
    if (filterParts) {
      for (let i = 0; i < filterParts?.length; i++) {
        // Check for consecutive or mismatched brackets
        if (
          filterParts[i].startsWith('[') &&
          filterParts[i].endsWith(']') &&
          filterParts.length > 1 &&
          filterParts[i + 1].startsWith('[') &&
          filterParts[i + 1].endsWith(']')
        ) {
          return validationFailed;
        } else if (
          (filterParts[i].startsWith('[') && !filterParts[i].endsWith(']')) ||
          filterParts[i].startsWith(']')
        ) {
          return validationFailed;
        }
      }
    } else {
      return validationPassed;
    }

    // If all checks pass, return true
    return validationPassed;
  }

  /**
   * Custom filter function to apply filters on both parent and child rows.
   *
   * @param {any} headerValue - The header value object containing the input and column status.
   * @param {any} rowValue - The value of the current row for the specified column.
   * @param {any} rowData - The data object for the current row, which may include child rows.
   * @param {any} filterParams - Additional parameters passed to the filter.
   *
   * @returns {boolean} - Returns `true` if the row or any of its child rows match the filter.
   */
  customFilterFunction = (
    headerValue: any,
    rowValue: any,
    rowData: any,
    filterParams: any
  ) => {
    // Split the input string into filter types and filter strings
    let filterParts :string[] = headerValue.inputValue.match(/\[.*?\]|[^ ]+/g);

    // Validate input, return true if the input is invalid to avoid filtering out rows
    const validator = this.validateInput(headerValue.inputValue);
    if (!validator) return true;

    // Create filter objects based on the parsed input parts
    const filters: string[] = this.createFilters(filterParts);

    // If no filters are found, return true to show all records
    if (filters.length == 0) {
      return true;
    }

    // Perform special replacement if column status requires it
    if (this.checkColStatus(headerValue.inputColumnStatus)) {
      const search = ';';
      const replacement = " ";

      // Create a regular expression with the global flag
      rowValue = rowValue?.replace(new RegExp(search, 'g'), replacement);
    }

    // Apply filter on parent row
    let parentMatches = rowValue && headerValue.inputValue && this.matchesFilter(rowValue, filters);

    // Apply filter on child rows if the parent does not match
    let childMatches = false;
    if (rowData._children && Array.isArray(rowData._children)) {
      childMatches = rowData._children.some((child: any) => this.checkChildMatch(child, headerValue, filters));
    }

      // const orFilteration = filters.some((filter: any) => {
      //   return filter.types.some((filterType: any) => {
      //     return filter.strings.some((filterString: any) => {
      //       switch (filterType) {
      //         case 'Or':
      //           // Implement "Or" logic as low precedence
      //           const fString = filter.strings.join(' ');
      //           return rowValue
      //             .toString()
      //             .toLowerCase()
      //             .includes(fString.toLowerCase());
      //         // TODO: Uncomment the below line of code, as this will be used to filter hyphrn (-) separated words
      //         // return filter.strings.some((str: any) => rowValue.toString().toLowerCase().includes(str.toString().toLowerCase()));
      //         default:
      //           return false;
      //       }
      //     });
      //   });
      // });
      // return filteration || orFilteration;


      // Return true if parent or any child matches
    // }

    return parentMatches ? parentMatches : childMatches;
  };

  /**
   * Recursive function to check if a child or any of its descendants match the filter.
   *
   * @param {any} child - The current child row to check.
   * @param {any} headerValue - The header value object containing the input column to match.
   * @param {Array} filters - The array of filters to apply for matching.
   *
   * @returns {boolean} - Returns `true` if the child or any of its descendants match the filter, otherwise `false`.
   */
  checkChildMatch(child: any, headerValue: any, filters: any): boolean {
    const childRowValue = child[headerValue.inputColumn]; // Adjust to match the child field
    
    // Check if the current child matches the filter
    if (this.matchesFilter(childRowValue, filters)) {
      return true;
    }

    // Recursively check if any descendants match the filter
    if (child._children && Array.isArray(child._children)) {
      return child._children.some((nestedChild: any) => this.checkChildMatch(nestedChild, headerValue, filters));
    }

    return false;
  }

  /**
   * Evaluates if a given value matches all specified filters.
   *
   * @param {any} value - The value to be checked against the filters.
   * @param {Array} filters - An array of filter objects containing filter types and filter strings.
   * 
   * @returns {boolean} - Returns `true` if the value matches all filters, otherwise `false`.
   */
  matchesFilter(value: any, filters: string[]): boolean {
    return filters.every((filter: any) => {
      return filter.types.every((filterType: any) => {
        return filter.strings.every((filterString: any) => {
          switch (filterType) {
            case 'Like':
              // Checks if the value contains the filter string
              return value.toString().toLowerCase().includes(filterString.toLowerCase());
            case 'Starting':
              // Checks if the value starts with the filter string
              return value.toString().toLowerCase().startsWith(filterString.toLowerCase());
            case 'Ending':
              // Checks if the value ends with the filter string
              return value.toString().toLowerCase().endsWith(filterString.toLowerCase());
            case 'Match':
              // Checks if the value matches the entire filter string
              return value.toString() === filter.strings.join(" ");
            case 'Regex':
              // Evaluates the value using a regular expression
              return new RegExp(filterString).test(value);
            case 'And':
              // Ensure the value contains all strings for 'And' condition
              return value.toString().toLowerCase().includes(filterString.toLowerCase());
            case 'Not':
              // Checks if the value does not contain the filter string
              return !value.toString().toLowerCase().includes(filterString.toLowerCase());
            default:
              // Default to 'Like' behavior if no valid filter type is provided
              return value.toString().toLowerCase().includes(filterString.toLowerCase());
          }
        });
      });
    });
  };

  /**
   * Checks if any of the provided statuses meet specific filtering rules related to items.
   *
   * @param {string[]} statuses - An array of status strings to check.
   * @returns {boolean} - Returns `true` if any status includes 'Item' and matches predefined rules; otherwise, returns `false`.
   */
  checkColStatus(statuses: []): boolean {
    // Define the rules for valid item statuses
    const rules = [
      'Item#≥0',
      '≥1',
      '≤2',
      '=2',
      '≥2',
    ];

    // Find the first status that includes the substring 'Item'
    const status = statuses.find((status:string) => typeof status === 'string' && status.includes('Item'));
    if (status && rules.includes(status)) return true;
    return false;
  }

  /**
   * Parses a filter type string and returns an array of corresponding filter types.
   *
   * @param {string} filterType - The filter type string to be parsed.
   * @returns {string[]} - An array of parsed filter types.
   */
  createFilters(filterParts: string[]): string[] {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];

    const filters: any = [];
    let currentFilterTypes: any = [];
    let currentFilterStrings: any = [];

    if (!filterParts) return [];
    if (filterParts.length == 0) return [];

    // Creaet filters based on provided parts of filter-type and filter-string
    for (let i = 0; i < filterParts.length; i++) {
      if (filterParts[i].startsWith('[') && filterParts[i].endsWith(']')) {
        // If there is an existing filter type and strings, push them to the filters array
        if (currentFilterStrings.length > 0) {
          filters.push({
            types:
              currentFilterTypes.length == 0 ? ['Like'] : currentFilterTypes,
            strings: currentFilterStrings,
          });

          currentFilterTypes = [];
          currentFilterStrings = [];
        }

        // Push parsed filter type
        const filterType = this.parseFilterType(filterParts[i]);
        currentFilterTypes.push(...filterType);
      } else {
        // Accumulate filter strings
        currentFilterStrings.push(filterParts[i]);

        // TODO: Uncomment the below line of code, as this will be used to filter hyphrn (-) separated words
        // currentFilterStrings.push(parts[i].replace(/-+/g, ' '));
      }
    }

    // Push the last filter type and strings
    if (currentFilterTypes.length > 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: currentFilterTypes.map((type: any) => type),
        strings: currentFilterStrings,
      });
    }

    // Apply the "Like" if filter-type is not present
    if (currentFilterTypes.length == 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: ['Like'],
        strings: currentFilterStrings,
      });
    }

    // Push empty filter-type for empty filter-type
    if (currentFilterTypes.length > 0 && currentFilterStrings.length == 0) {
      filters.push({
        types: currentFilterTypes,
        strings: currentFilterStrings,
      });
    }

    return filters;
  }

  parseFilterType(filterType: string) {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];
    if (!filterType.slice(1, -1)) {
      return ['[]'];
    }
    const parsedFilterType = filterType
      .slice(1, -1)
      .split(' ')
      .map(
        (type: string) => type[0].toUpperCase() + type.slice(1).toLowerCase()
      )
      .map((type: string) => {
        let processedType = type;
        if (type == 'Start(ing)') {
          processedType = 'Starting';
        } else if (type == 'End(ing)') {
          processedType = 'Ending';
        }
        return processedType;
      });

    return parsedFilterType;
  }

  // Custom filter function
  filterData(rowData:any, filterString:any) {
    const filterLower = filterString.toLowerCase();
  
    // Base case: Check if there are no children
    if (!rowData?._children || rowData._children.length === 0) {
      return rowData?.page_name?.toLowerCase().includes(filterLower)
        ? { page_name: rowData.page_name }
        : null;
    }
  
    // Check if the parent matches the filter string
    const parentMatches = rowData?.page_name?.toLowerCase().includes(filterLower);
  
    // Recursively check children for matches
    const matchedChildren = rowData._children
    .map((child: any) => this.filterData(child, filterString)) // Call recursively
    .filter((child: any) => child !== null); // Remove null results

  // If there are matched children, return them without the parent
  if (matchedChildren.length > 0) {
    // debugger;
    return matchedChildren; // Return matched children
  }

  // If the parent matches and no children matched, return the parent
  if (parentMatches && matchedChildren.length === 0) {
      return { page_name: rowData.page_name }; // Return only the parent if no children matched
  }

  // If neither matches, return null or handle accordingly
  return null; // or whatever you need in case of no matches

  }
}
