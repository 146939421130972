export class SheetModel {
  visitedPages: any[] = []; // Initialized as an empty array
  // Make type more explicit
  addRow: any[] = [];
  sheetData: [] = [];
  PickDdiData:[] = []
  PickDdiColumns:[] = []
  sheetColumns: [] = [];
  sheetPageId: number | undefined;
  filteredSheetData: [] = [];
  regions = [];
  formatLocalCol=null;
  deleteItemData=null;
  frozen=0;
  expandWidth=null;
  constructor() {}
}
